@charset "utf-8";

/* **************************
 　　共通
************************** */

html,body {
	width:100%;
	height: 100%;
	margin: 0;
	padding: 0;
	font-family: "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	color: #464646;
	font-size:14px;
	line-height: 1.8em;

}
a {text-decoration: none;color:#444444;}
a:hover { color:#666666;  text-decoration: none;}
a:hover img { filter:alpha(opacity=50); -moz-opacity:0.50; opacity:0.50;text-decoration: none;}

.clear {clear: both;}

.txt01{font-size:140%;font-weight: bold;color:#fff;}
.txt01 a{color:#fff;}
.txt02{font-weight: bold;}
.txt03{font-size:30px;text-decoration: underline;}
.txt-right{text-align:right;}
.txt-bold{font-weight: bold;}

#wrapper{width:100%;overflow: hidden;position: relative;}


/* **************************
 　　ヘッダー
************************** */

header{position: sticky;}

h1{width:95px;}
h1 img{width:100%;}
#wrap01{
	display: flex;
	align-items: center;
	width:100%;
	height: 60px;
	box-sizing: border-box;
	position: fixed;
	margin:0;
	padding: 15px;
	background:#fff;
	z-index:10;
}


/* **************************
 　　バーガーボタン
************************** */

.menu-trigger,
.menu-trigger span {
	display: inline-block;
	transition: all .4s;
	box-sizing: border-box;
}
.menu-trigger {
	position: fixed;
	top: 8px;
	right: 10px;
	width: 40px;
	height: 40px;
	z-index: 9999;
	color:#000;
	border-radius: 4px;
}
a.menu-trigger:hover{color:#000;}
.menu-trigger span {
	position: absolute;
	z-index: 9999;
	left: 8px;
	width: 75%;
	height: 3px;
	background-color: #ec7300;
	border-radius: 4px;
}
.menu-trigger span:nth-of-type(1) {top: 9px;}
.menu-trigger span:nth-of-type(2) {top: 20px;}
.menu-trigger span:nth-of-type(3) {bottom: 6px;}
.menu-trigger.active span:nth-of-type(1) {
	-webkit-transform: translateY(10px) rotate(-45deg);
	transform: translateY(9px) rotate(-45deg);
	background-color: #fff;
}
.menu-trigger.active span:nth-of-type(2) {opacity: 0;}
.menu-trigger.active span:nth-of-type(3) {
	-webkit-transform: translateY(-12px) rotate(45deg);
	transform: translateY(-12px) rotate(45deg);
	background-color: #fff;
}


/* **************************
 　　オーバーレイナビ
************************** */

#overlay{
	display: none;
	width: 100%;
	height:100%;
	text-align: left;
	position: fixed;
	top: 0;
	left:0;
	z-index: 9999;
	background: #ec7300;
	margin:0;
	padding: 0 8%;
	box-sizing: border-box;
}
#overlay ul{
	margin-top:40px;
	padding-left: 0px;
}
#overlay ul li{
	margin:0;
	padding:0;
	list-style-type: none;
}
#overlay ul li a{
	display:block;
	padding: 8% 0;
	color: #fff;
	text-decoration: none;
	font-size: 15px;
	line-height: 1.8em;
}
#overlay ul li a:hover{color:rgba(255,255,255,0.6);}
#overlay .logo{padding:30px 0 0 0;text-align:center;}
#overlay .logo img{width:30%;}
.navi1{border-bottom:2px solid rgba(255,255,255,0.6);}
// .navi3{display: none;}
.navi4{display: none;}
.navi5{
	width:100%;
	background:rgba(255,255,255,0.6);
	text-align:center;
}
.navi5 a{
	display:block;
	padding:0 0 5% 0;
}
.navi5 ul{width:100%;}
.navi5 li{
	margin:0;
	padding:0;
	display:inline-block;
	width:50%;
	box-sizing: border-box;
	text-align:center;
	font-size: 80%;
}
.navi5 li:first-child{border-right:2px solid rgba(255,255,255,0.6);}
.navi5 li a{margin:0;padding:0;}
.navi5 li img{width:40px;padding:0 0 8px 0;}
// .navi6{display: none;}
.line li{vertical-align:top;box-sizing: border-box;float: left;}


/* **************************
 　　メインイメージ
************************** */

#main01{display: none;}
#main01-p{display: none;}
#main01-s{
	margin:0;
	padding:0;
	animation: fadeIn 8s ease 0s 1 normal;
	-webkit-animation: fadeIn 8s ease 0s 1 normal;line-height:0.5em;
}
#main01-s img{
	width:100%;
	margin:40px 0 0 0;
	padding:0;
}
@keyframes fadeIn {
	0% {opacity: 0}
	100% {opacity: 1}
}

@-webkit-keyframes fadeIn {
	0% {opacity: 0}
	100% {opacity: 1}
}
.box{display: none;}
.m-logo{display: none;}
.m-text{display: none;}


/* **************************
 　　ニュース
************************** */

#news-s{
	width: 100%;
	background: #f3971c;
	background: -moz-linear-gradient(top, #f3971c 0%, #ba461f 100%);
	background: -webkit-linear-gradient(top, #f3971c 0%,#ba461f 100%);
	background: linear-gradient(to bottom, #f29300 0%,#ba4620 100%);
	text-align:center;
	font-family: "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;;
}
#news-s h2{
	width:90%;
	color:#fff;
	font-size:18px;
	font-weight: bold;
	text-align:center;
	line-height: 130%;
	display: inline-block;
	margin:20px 5%;
	padding:0 20px;
	box-sizing: border-box;
	border-left:8px solid #fff;
	border-right:8px solid #fff;
}

#news{display:none;}


/* **************************
 　　ご挨拶
************************** */

.greet{
	font-family: "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;;
	width:100%;
	margin:50px auto;
}
.greet h3{
	margin:0 5%;
	padding:0 0 10px 0;
	font-size:24px;
	line-height: 1.5em;
	text-align:center;
	border-bottom:1px solid #000;
}
.greet p{
	margin:0 5%;
	padding:10px 0 0 0;
	font-size:14px;
	line-height: 1.8em;
}

/* **************************
 　　内覧会のご案内
************************** */

#guide-h{
	width:100%;
	background:#f1ede2;
	padding:50px 0;
}
#guide{
	width:90%;
	margin:0 5%;
}

.pc{display:none;}
.tb{display:none;}
.sp{
	margin:0;
	padding:0 0 10px 0;
	font-size:24px;
	line-height: 1.5em;
	color:#ee7800;
	border-bottom:1px solid #fff;
	text-align:center;
}
.sp img{width:35px;}

.g-group01{display:none;}
.g-group02{display:none;}
.g-group03{width:100%;}
.g-group04{display:none;}
.g-group05{display:none;}
.g-group06{display: none;}
.g-group07{display:none;}
.g-group08{display: none;}
#g04{
	width:100%;
	margin:0;
	padding:30px 0 0 0;
}
#g04 img{width:100%;}

#g01{
	padding:30px 0 10px 0;
	font-size:14px;
	line-height: 1.8em;
}
#g02{margin:0 0 20px 0;}
#g02 .icon02{
	width:100%;
	text-align:center;
	position: relative;
}
#g02 .icon02 img{width:112px;}
#g02 .con01{
	display:block;
	width:90%;
	padding:35px 5% 20px 5%;
	margin:-25px 0 0 0;
	background:#fff;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;

}
#g02 .con01 h5{
	font-size:17px;
	line-height: 1.3em;
	color:#ee7800;
	border-bottom:1px solid #ee7800;
	width:100%;
	text-align:center;
	padding-bottom:7px;
}
#g02 .con01 p{
	padding:10px 0 0 0;
	font-size:14px;
	line-height: 1.8em;
}



#g03{margin:0 0 20px 0;}
#g03 .icon02{
	width:100%;
	text-align:center;
	position: relative;
}
#g03 .icon02 img{width:112px;}
#g03 .con01{
	display:block;
	width:90%;
	padding:35px 5% 20px 5%;
	margin:-25px 0 0 0;
	background:#fff;
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
}
#g03 .con01 h5{
	font-size:17px;
	line-height: 1.5em;
	color:#ee7800;
	border-bottom:1px solid #ee7800;
	width:100%;
	text-align:center;
	padding-bottom:7px;
}
#g03 .con01 p{
	padding:10px 0 0 0;
	font-size:14px;
	line-height: 1.8em;
}


#g05{
	width:100%;
	background: #f3971c;
	background: -moz-linear-gradient(top, #f3971c 0%, #ba461f 100%);
	background: -webkit-linear-gradient(top, #f3971c 0%,#ba461f 100%);
	background: linear-gradient(to bottom, #f29300 0%,#ba4620 100%);
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	margin:40px 0;
	padding:0;
	text-align:center;
	color:#fff;
	line-height: 1.5em;
	font-size:100%;
}
#g05 a{
	width:90%;
	display:block;
	color:#fff;
	padding:10px 5%;
}
#g05 p{margin-bottom:16px;}



.map h5{
	font-size:18px;
	line-height: 1.5em;
	padding-bottom:12px;
	width:100%;
	text-align:center;
}
.center{text-align:center;}


/* **************************
 　　施設概要
************************** */

.facility {
	width:90%;
	margin:0 5%;
	padding:30px 0 0 0;
}
.facility h5{font-size:18px;line-height: 1.5em;}
.summary{
	width:100%;
	margin:20px auto 20px auto;
	padding:35px 0 20px 0;
	border-top:1px solid #999999;
}
.summary tr{border-bottom:1px solid #999999;}
.summary th{
display: block;
	padding:15px 0 0 0;
	font-size:14px;
	text-align:left;
	line-height: 1.8em;
}
.summary td{
	display: block;
	padding:0 0 15px 0;
	font-size:14px;
	text-align:left;
	line-height: 1.8em;
}
.map {
	width:100%;
	margin:60px auto 0 auto;
}
.map iframe{width:100%;height:200px;}


/* **************************
 　　法人概要
************************** */

#corpo{
	width:90%;
	margin:0 5%;
	padding:50px 0;
}
#corpo h3{
	font-size:24px;
	color:#ee7800;
	text-align:center;
	line-height: 1.5em;
}



/* **************************
 　　フッター
************************** */

#footer{
	display: block;
	width:100%;
	background: #f3971c;
	background: -moz-linear-gradient(top, #f3971c 0%, #ba461f 100%);
	background: -webkit-linear-gradient(top, #f3971c 0%,#ba461f 100%);
	background: linear-gradient(to bottom, #f29300 0%,#ba4620 100%);
	color:#fff;
	padding:20px 0;
	text-align:center;
}
#footer ul{
	display: inline-block;
	margin:20px 0;
}
#footer li{
	list-style-type: none;
	margin:0;
	padding:10px 5%;
	font-size:14px;
}
#footer li:last-child{border:none;}
#footer img{width:48%;}
#footer .copy{font-size:12px;}


/* **************************
 　　ページトップ
************************** */

#page-top{display: none!important;}


/* **************************
 　　SP固定ナビ
************************** */

#sp-fix{
	position: fixed;
	left:0;
	bottom:0;
	width:100%;
	background: #57bbec;
	background: -moz-linear-gradient(top, #57bbec 0%, #00175d 100%);
	background: -webkit-linear-gradient(top, #57bbec 0%,#00175d 100%);
	background: linear-gradient(to bottom, #57bbec 0%,#00175d 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#57bbec', endColorstr='#00175d',GradientType=0 );

	animation: fadeIn 1s ease 0s 1 normal;
	-webkit-animation: fadeIn 1s ease 0s 1 normal;line-height:0.5em;
}
@keyframes fadeIn {
	0% {opacity: 0}
	100% {opacity: 1}
}

@-webkit-keyframes fadeIn {
	0% {opacity: 0}
	100% {opacity: 1}
}
#sp-fix ul{width:100%;}
#sp-fix li{
	margin:10px auto;
	padding:0;
	display:inline-block;
	width:48%;
	box-sizing: border-box;
	text-align:center;
	font-size: 100%;
}
#sp-fix li:first-child{border-right:2px solid rgba(255,255,255,0.6);}
#sp-fix li a{
	margin:0;
	padding:3px 0;
	display:flex;
	align-items: center;
	justify-content: center;
	text-align: left;
	line-height: 1.4;
	color:#fff;
}
#sp-fix li a span{
	margin-left: 10px;
}
#sp-fix li a:hover{color:rgba(255,255,255,0.6);}
#sp-fix li img{
	width:25px;
	padding:5px 5px 8px 0;
	vertical-align:middle;
}

.is-fixed {
	display: none!important;
	animation: fadeout 1s ease 0s 1 normal;
	-webkit-animation: fadeout 1s ease 0s 1 normal;line-height:0.5em;
}
@keyframes out {
	0% {opacity: 1}
	100% {opacity: 0}
}

@-webkit-keyframes Out {
	0% {opacity: 1}
	100% {opacity: 0}
}


/* **************************
 　　js周り
************************** */

/* ------------- スクロールフェードインDown用 ------------- */
.fadeInDown {
	-webkit-animation-fill-mode:both;
	-ms-animation-fill-mode:both;
	animation-fill-mode:both;
	-webkit-animation-duration:1s;
	-ms-animation-duration:1s;
	animation-duration:1s;
	-webkit-animation-name: fadeInDown;
	animation-name: fadeInDown;
	visibility: visible !important;
}
@-webkit-keyframes fadeInDown {
	0% { opacity: 0; -webkit-transform: translateY(20px); }
	100% { opacity: 1; -webkit-transform: translateY(0); }
}
@keyframes fadeInDown {
	0% { opacity: 0; -webkit-transform: translateY(20px); -ms-transform: translateY(20px); transform: translateY(20px); }
	100% { opacity: 1; -webkit-transform: translateY(0); -ms-transform: translateY(0); transform: translateY(0); }
}

.fadeInDown1 {
	-webkit-animation-fill-mode:both;
	-ms-animation-fill-mode:both;
	animation-fill-mode:both;
	-webkit-animation-duration:3s;
	-ms-animation-duration:3s;
	animation-duration:3s;
	-webkit-animation-name: fadeInDown;
	animation-name: fadeInDown;
	visibility: visible !important;
}
@-webkit-keyframes fadeInDown1 {
	0% { opacity: 0; -webkit-transform: translateY(30px); }
	100% { opacity: 1; -webkit-transform: translateY(0); }
}
@keyframes fadeInDown1 {
	0% { opacity: 0; -webkit-transform: translateY(30px); -ms-transform: translateY(30px); transform: translateY(30px); }
	100% { opacity: 1; -webkit-transform: translateY(0); -ms-transform: translateY(0); transform: translateY(0); }
}
/* ------------- スクロールフェードインDown用 END ------------- */
